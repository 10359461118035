//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name : "validarCantidadesModal",
  props: {
    producto: {
      type: Object, default: () => {
        return {}
      }
    },
    model: Boolean,
    tipoVenta: String
  },
  data() {
    return {
      cantidad   : "",
      cantidadW  : "",
      largo      : "",
      ancho      : "",
      profundidad: "",
    }
  },
  methods: {
    onClose() {
      this.$emit("on-close")
    },
    onShow() {
      this.cantidad    = this.producto.cantidad;
      this.largo       = this.producto.cantidadX;
      this.ancho       = this.producto.cantidadY;
      this.profundidad = this.producto.cantidadZ;
      this.cantidadW   = this.producto.cantidadW;

      this.$nextTick(() => {
        if (Number(this.producto.tipoCantidad) === 100) this.$refs.cantidad.select();
        if (Number(this.producto.tipoCantidad) !== 100) this.$refs.largo.select();
      });
    },
    calcularCantidades() {
      let total = 1;
      if (Number(this.producto.tipoCantidad) === 200) {
        if (this.largo && this.cantidadW) {
          let largo     = this.Decimal(this.largo);
          let cantidadW = this.Decimal(this.cantidadW);
          total         = largo.times(cantidadW).toString();
        }
      } else if (Number(this.producto.tipoCantidad) === 300) {
        if (this.largo && this.ancho && this.cantidadW) {
          let largo     = this.Decimal(this.largo);
          let ancho     = this.Decimal(this.ancho);
          let cantidadW = this.Decimal(this.cantidadW);

          total = largo.times(ancho).times(cantidadW).toString();
        }
      } else if (Number(this.producto.tipoCantidad) === 400) {
        if (this.largo && this.ancho && this.profundidad && this.cantidadW) {
          let largo       = this.Decimal(this.largo);
          let ancho       = this.Decimal(this.ancho);
          let profundidad = this.Decimal(this.profundidad);
          let cantidadW   = this.Decimal(this.cantidadW);

          total = largo.times(ancho).times(profundidad).times(cantidadW).toString();
        }
      } else {
        total = this.cantidad;
      }
      this.cantidad = total;
    },
    aplicar() {
      let productoClon = _.clone(this.producto),
          stock        = productoClon.tipoProducto === 104 ? (productoClon.stockLocalRelacionado /
                                                              productoClon.cantidadEquivalente) : productoClon.stock;

      //Se valida que cantidad sea mayor a 0
      if (this.cantidad < 1)
        return this.alertShow('La cantidad del producto no puede ser 0', "warning");

      if (this.tipoVenta !== "Cotizacion" && this.cantidad > stock && productoClon.facturarSinExistencias === 0)
        return this.alertShow(`Cantidad maxima por encima del stock registrado. Cantidad maxima permitida ${stock} pzas.`, "error");

      productoClon.cantidadX = this.largo;
      productoClon.cantidadY = this.ancho;
      productoClon.cantidadZ = this.profundidad;
      productoClon.cantidadW = this.cantidadW;
      productoClon.cantidad  = this.cantidad;

      this.$emit("aplicar", productoClon);
    }
  }
}
